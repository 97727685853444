const myMixin = {
  props: {
    storeItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      numberSelect: 1,
      numOption: new Array(5).fill().map((it, index) => {
        return {
          label: index + 1,
          value: index + 1,
        };
      }),
      storeDetail: null,
      textAreaMessage: '',
    };
  },
  created() {},
  computed: {
    storeType() {
      return this.storeDetail?.type;
    },
    storeName() {
      return this.storeDetail?.name;
    },
    storePrice() {
      return this.storeDetail?.price;
    },
    storeDescribe() {
      return this.storeDetail?.describe;
    },
  },
  watch: {
    storeItems: {
      handler: function () {
        if (this.storeItems.length !== 0) {
          const findRes = this.storeItems.find((it) => it.id === this.$route.query.id);
          if (findRes) {
            this.storeDetail = findRes;
          } else {
            alert('产品已下架');
            this.$router.replace({ name: 'LaTen' });
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    addCart(routeName) {
      this.$store.commit('setLoading', true);
      setTimeout(() => {
        this.$store.commit('addCarts', {
          id: this.storeDetail.id,
          type: this.storeType,
          name: this.storeName,
          price: this.storePrice,
          describe: this.storeDescribe,
          sum: this.numberSelect,
          web: routeName,
          message: this.textAreaMessage,
        });
        this.$store.commit('setLoading', false);
        this.$notify({
          title: '成功',
          message: '已添加至购物车',
          type: 'success',
        });
      }, 300);
    },
  },
};

export default myMixin;
